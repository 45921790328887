import styled from 'styled-components';
import { SearchBarContainer } from '../../NewSearch/NewSearchBar/styled';
import { animation } from '../../../styled/placeholders.styled';

export const NewSearchBarPlaceholderContainer = styled(SearchBarContainer)`
  gap: 10px;
  width: auto;
  display: flex;

  .location,
  .button {
    height: 54px;
    background-color: ${({ theme: { colors } }) => colors.background.secondary};
    border-radius: 14px;
    ${animation};
  }

  .location {
    flex: auto;
  }

  .button {
    width: 53px;
  }
`;
